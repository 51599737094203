/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

@import '../../node_modules/@ng-select/ng-select/themes/default.theme.css';

// Bootstrap sizes: https://getbootstrap.com/docs/4.0/layout/overview/#containers
@xs-max: 575.98px;
@sm-max: 767.98px;
@md-max: 991.98px;
@lg-max: 1199.98px;

@sm-min: 576px;
@md-min: 768px;
@lg-min: 992px;
@xl-min: 1200px;

// Do not use the below styles; they should be used to define the next section of styles
@_darkBlueGrey: #335069;
@_lightBlueGrey: #5472AB;
@_lightestBlueGrey: #748ab1;

// These styles should be used instead of the above styles
@primary: @_darkBlueGrey;
@lightPrimary: @_lightBlueGrey;
@lightestPrimary: @_lightestBlueGrey;
@smallerFontSizeForMobile: 0.8rem;

// ------------------------------ Scully stuff ------------------------------ //

// These two styles are here to ensure the ACTUAL content is always displayed, rather than showing the
// PRERENDERED content generated by scully. The reason for this change is that the scully prerendered content
// may be out of date, and the user should not see the content change -- they should instead be shown the
// content ONLY once it bootstraps
//
// Note that the below two styles are opposite of what is described in this documentation, where scully prerendering
// is intended to show before seemlessly switching to loaded content:
// https://github.com/scullyio/scully/tree/main/libs/plugins/scully-plugin-flash-prevention

body:not(.loaded) app-root {
    display: inherit !important;
}

body:not(.loaded) app-root-scully {
    display: none !important;
}

// ------------------------------ HTML elements ------------------------------ //

@_smallWidth: 450px;
@_smallerWidth: 275px;
@_zoomOut: 0.9;
@_zoomOutMore: 0.7;
html {
    overflow-x: hidden;

    // Scale down page when at small sizes
    @media (max-width: @_smallWidth) {
        // zoom: @_zoomOut !important;

        mt-paypal-buttons {
            zoom: (1 / @_zoomOut) !important;
        }
    }

    @media (max-width: @_smallerWidth) {
        // zoom: @_zoomOutMore !important;

        mt-paypal-buttons {
            zoom: (1 / @_zoomOutMore) !important;
        }
    }
}

// This uses a known CSS hack to apply styles only to Safari
_::-webkit-full-page-media {
    // Safari (and iPhones in general) render certain elements differently when using CSS's non-standard "zoom"
    // The "size-constant" class should be applied to any elements affected unexpectedly by "zoom"
    .size-constant {
        @media (max-width: @_smallWidth) {
            zoom: (1 / @_zoomOutMore) !important;
        }

        @media (max-width: @_smallerWidth) {
            zoom: (1 / @_zoomOutMore) !important;
        }
    }
}

body {
    font-family: 'Open Sans', sans-serif !important;
    color: black;
}

body h1 {
    color: @primary;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    font-weight: 500;
}

body h2 {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
}

body h3 {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 20px;
}

body h4 {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
}

body h5 {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
}

a {
    color: #3333ff;
    white-space: nowrap;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

body a {
    color: #3333ff;
    &:hover {
        color: #0056b3;
    }
}

.header-main-nav a {
    color: #fff !important;
}

.account-links a {
    color: #335069 !important;
    &:hover {
        color: #5474af !important;
    }
}

.login-form-2 a {
    color: #fff !important;
}

.footer-text a {
    color: #ffffff80 !important;
    &:hover {
        color: #5474af!important;
    }
}

.social-media a {
    color: #ffffff !important;
    &:hover {
        color: #5474af!important;
    }
}

app-page-content a {
    color: #3333ff !important;
    &:hover {
        color: #0056b3 !important;
    }
}

.course-sort > a {
    color: #3333ff !important;
    &:hover {
        color: #0056b3 !important;
    }
}

.header a,
a > .fa {
    color: @primary;
    white-space: nowrap;

    &:hover {
        color: @lightPrimary;
    }
}

b,
strong {
    font-weight: 700;
}

// ------------------------------ End HTML elements ------------------------------ //

// ------------------------------ Button styles ------------------------------ //
/*
.btn {
    border-radius: 0%;
    color: #fff;
}
*/
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited {
    background-color: @lightPrimary !important;
    border-color: @lightPrimary !important;
    color: #fff !important;
}

.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.focus.active,
.btn-primary.focus.active:hover,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:hover {
    background-color: darken(@lightPrimary, 12%);
    border-color: darken(darken(@lightPrimary, 12%), 5%);
    color: #fff !important;

}

.btn-default {
    color: @primary;
}
// For the entity list add ONLY (ex: Billing address add button)
mt-entity-list .btn-add {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

// ------------------------------ End button styles ------------------------------ //

// ------------------------------ Entity list stuff ------------------------------ //

.custom-entity-list {
    .table-responsive {
        overflow-x: visible;
    }

    tbody {
        display: grid !important;
        grid-gap: 10px !important;
    }

    &.one-column tbody {
        grid-template-columns: 1fr;
    }

    &.two-column tbody {
        grid-template-columns: 1fr 1fr;

        @media (max-width: @md-max) {
            grid-template-columns: 1fr;
        }
    }

    &.three-column tbody {
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: @lg-max) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: @md-max) {
            grid-template-columns: 1fr;
        }
    }
}

// entity-list-card is a class in "entity-list" and does not appear in this app's code
.entity-list-card {
    border: 1px solid rgb(236, 234, 234);
    margin: 2px;
}

// ------------------------------ End entity list stuff ------------------------------ //

// ------------------------------ Login stuff ------------------------------ //

.login-container {
    margin-top: 5%;
    margin-bottom: 5%;
}

.login-form-1 {
    padding: 5%;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-1 .message {
    color: #333;
}

.login-form-2 {
    padding: 5%;
    background: @primary;
    color: #fff;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-2 .message {
    color: #fff;
}

.login-container form {
    padding: 10%;
}

.login-container .message {
    font-size: 1.5em;
    text-align: center;
}

// ------------------------------ End login stuff ------------------------------ //

// ------------------------------ Swal stuff ------------------------------ //

body > .swal2-container .swal2-popup {
    font-size: 1rem;
}

.swal2-shown.swal2-height-auto {
    padding-right: 0 !important;
}

.swal2-content {
    color: black !important;
}

.swal2-title {
    color: black !important;
}

@media all and (max-width: @lg-min) {
    .swal2-content {
        padding: 0px !important;
    }

    .course-text {
        padding-right: 15px;
        padding-left: 15px;
        width: 100%;
        position: relative;
    }
}

// ------------------------------ End Swal stuff ------------------------------ //

// ------------------------------ Quiz stuff ------------------------------ //

.multi-choice-question {
    position: relative;
    height: auto;

    &.active-choice:not(.show-is-correct, .show-is-incorrect) {
        background-color: @lightPrimary;
        color: white;
    }

    &:hover {
        background-color: @lightestPrimary;
        color: white;
    }

    input {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}

.quiz-content {
    position: relative;
    border: 1px black solid;
    background-color: white;
    user-select: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    // padding-top: 3.5rem;
    margin: 1rem 0;
    &.no-min {
        min-height: auto;
    }
}

.light-red-background {
    background-color: rgba(pink, 0.5);
    border: 1px solid red;
}

.light-green-background {
    background-color: rgba(lightgreen, 0.5);
    border: 1px solid green;
}

.light-green-button {
    background-color: #44a33d;
    color: white;
    border: solid darkgreen 1px;

    &:disabled {
        background-color: grey;
        color: black;
        border: solid #555 1px;
    }

    &:hover,
    &:active {
        background-color: darkgreen;
        color: white;
    }
}

.green-button {
    background-color: #005f00;
    color: white;
    border: solid darkgreen 1px;

    &:disabled {
        background-color: grey;
        color: black;
        border: solid #555 1px;
    }

    &:hover,
    &:active {
        background-color: darkgreen !important;
    }
}

// ------------------------------ End Quiz stuff ------------------------------ //

// ------------------------------ Layout stuff ------------------------------ //

.course-card {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;

    &.with-buttons {
        grid-template-columns: auto 1fr auto;

        &.collapsible {
            @media (max-width: @sm-max) {
                grid-template-columns: 1fr auto;

                .collapse-column {
                    grid-row: 2;
                    grid-column-end: span 2;
                }
            }
        }
    }

    &.collapsible {
        @media (max-width: @sm-max) {
            grid-template-columns: auto;
        }
    }
}

.grid-note {
    display: grid;
    grid-template-columns: 1fr auto;
}

.colon-aligned-data {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 8px;

    &.colon-centered {
        grid-template-columns: 1fr 1fr;
    }

    div {
        text-align: end;
    }
}

.phone-number {
    width: 100%;
    height: 85px;
    display: grid;
    grid-template-columns: 1fr auto;

    .extension {
        margin-left: 10px;
        width: 65px;
    }
}

.dynamic-field-label[for='HomePhone'] {
    margin-bottom: 0;
}

.dynamic-field-label[for='WorkPhone'] {
    margin-bottom: .5rem;
}

.dynamic-field-label[for='HomeExt'] {
    margin-bottom: .5rem;
}

.dynamic-field-label[for='WorkExt'] {
    margin-bottom: .5rem;
}

.vertical-button-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5em;
    margin-top: 1em;
}

// ------------------------------ End layout stuff ------------------------------ //

// ------------------------------ Overrides ------------------------------ //

.miles-card {
    padding: 1rem;

    &.padded {
        padding: 2.2rem;
    }
}

.miles-card,
.miles-card b,
.miles-card h4,
.miles-form,
.miles-form h4,
.form-control {
    color: @primary;
}

.hidden {
    display: none !important;
}

// ------------------------------ End overrides ------------------------------ //

// ------------------------------ Media queries ------------------------------ //

@media (max-width: @sm-max) {
    body h1 {
        font-size: 1.5rem;
    }

    body h2 {
        font-size: 1.4rem;
    }

    body h3 {
        font-size: 1.3rem;
    }

    body h4 {
        font-size: 1.2rem;
    }

    body h5 {
        font-size: 1.3rem;
    }

    .course-title {
        font-size: 1rem;
    }

    .sm-full-width {
        width: 100%;
    }
}

// ------------------------------ End media queries ------------------------------ //

// ------------------------------ Misc stuff ------------------------------ //

.set-full-height {
    height: 100%;
}

.set-full-width {
    width: 100%;
}

.course-img {
    object-fit: cover;
    width: 105px;
    height: 105px;
    border: solid black 1px;
    font-size: 10px;
}

.no-animate {
    transform: none !important;
}

.fa-top {
    position: relative;
    top: -2px;
}

.adminClass {
    background-color: #ffe7e7 !important;
}

.lower-opacity {
    opacity: 0.4;
}

.bottom-checkout-card {
    margin-bottom: 30px;
}

.centerElement {
    display: flex;
    justify-content: center;
}

.ng-dropdown-panel-items {
    max-height: 40vh !important;
}

.float-multiple-right {
    display: flex;
    justify-content: right;
}

.qty-input {
    text-align: center;
    width: 55px;
}

.course-title {
    color: @primary;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    font-weight: bold;
    line-height: 1.5rem;
}

.bottom-right-fixed,
.trashcan {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.answer-invalid-textarea {
    border-color: #dd4b39;
    box-shadow: none;
}

// Move pagination buttons to the right side
.pagination li:first-child {
    margin-left: auto;
}

.quiz-course-title {
    color: @primary;
}

.page-link {
    color: @primary !important;
}

.page-item.active .page-link {
    background-color: @lightPrimary;
    color: white !important;
}

.input-group-addon {
    font-size: 20px;
    margin: auto 2px;
}

.remove-breck-error {
    .errortext.small:not(.custom-error) {
        display: none;
    }

    .custom-error {
        margin-top: -1.3em;
    }
}
.container-scroll-override {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}


.bookmark-popup-order-1 {
    order: 1;
}


.bookmark-popup-order-2 {
    order: 2;
}

.bookmark-popup-order-3 {
    order: 3;
}

.vertical-buttons {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons horizontally */
    gap: 10px; /* Add spacing between buttons */
}

.dropdown-toggle {
    color:#000000 !important;
}

.vertical-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
}
// ------------------------------ End Misc ------------------------------ //
